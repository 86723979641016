
import Vue from "vue"
const CountryFlag = () => import("vue-country-flag")
type LangToFlagMap = {
	[k: string]: {
		flag: string
		name: string
	}
}
const langToFlagMap: LangToFlagMap = {
	"en": {
		flag: "gb",
		name: "English"
	},
	/*
	"no": {
		flag: "no",
		name: "Norwegian"
	}
	*/
}
export default Vue.extend({
	name: "LocaleDropdown",
	data(): {
		selectedLanguage: string
		langToFlagMap: LangToFlagMap
	} {
		return {
			selectedLanguage: "en",
			langToFlagMap: langToFlagMap
		}
	},
	components: {
		CountryFlag
	},
	methods: {
		changeLanguage(): void {
			// dispatch instant language change in vuex or similar (look at widget for examples ...)
			console.log("Changed language to: " + this.selectedLanguage)
		}
	},
	computed: {
		languagesSelectList(): string[] {
			const list: string[] = ["en", "no"]
			return list
		},
	}
})
